export const navLinks = [
  {
    name: "About",
    url: "#about",
  },
  {
    name: "Experience",
    url: "#experience",
  },
  {
    name: "Contact",
    url: "#contact",
  },
];